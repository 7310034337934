<template>
  <div class="page">
    <div class="title">
      <span>血压概览</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="head">
        <div class="title">
          <span>血压趋势图</span>
        </div>
        <div class="chart1" id="chart1"></div>
      </div>
      <div class="footer">
        <div class="item">
          <div class="title">
            <span>血压统计</span>
          </div>
          <div class="tr border">
            <span class="td"></span>
            <span class="td">收缩压(mmHg)</span>
            <span class="td">舒张压(mmHg)</span>
            <span class="td">心率(次/分)</span>
          </div>
          <div class="tr">
            <span class="td">最高值</span>
            <span class="td">203</span>
            <span class="td">151</span>
            <span class="td">151</span>
          </div>
          <div class="tr">
            <span class="td">最低值</span>
            <span class="td">93</span>
            <span class="td">40</span>
            <span class="td">54</span>
          </div>
          <div class="tr">
            <span class="td">平均值</span>
            <span class="td">134</span>
            <span class="td">89</span>
            <span class="td">88</span>
          </div>
          <div class="tr">
            <span class="td">次数</span>
            <span class="td">134</span>
            <span class="td">89</span>
            <span class="td">88</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>血压总览</span>
          </div>
          <div class="chart2" id="chart2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.initChart1()
    this.initChart2()
  },
  methods: {
    initChart1 () {
      var chartDom = document.getElementById('chart1')
      this.chartInstance = this.$echarts.init(chartDom)
      const initOption = {
        title: {
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: 20
          },
          top: '5%',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(0, 255, 233,0)'
                }, {
                  offset: 0.5,
                  color: 'rgba(255, 255, 255,1)'
                }, {
                  offset: 1,
                  color: 'rgba(0, 255, 233,0)'
                }],
                global: false
              }
            }
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '15%'
          // containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            show: true
          },
          splitArea: {
            // show: true,
            color: '#f00',
            lineStyle: {
              color: '#f00'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          },
          boundaryGap: false,
          data: []
        }],
        yAxis: [{
          type: 'value',
          min: 0,
          // max: 140,
          splitNumber: 4,
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.1)'
            }
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            margin: 20,
            textStyle: {
              color: '#d1e6eb'

            }
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '高压(收缩压)',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            normal: {
              color: '#00b3f4',
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5
            }
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#00b3f4'
            }
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,179,244,0.3)'
              },
              {
                offset: 1,
                color: 'rgba(0,179,244,0)'
              }
              ], false),
              shadowColor: 'rgba(0,179,244, 0.9)',
              shadowBlur: 20
            }
          },
          data: [110, 120, 115, 118, 131, 103, 105]
        },
        {
          name: '低压(舒张压)',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            normal: {
              color: '#00b3f4',
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5
            }
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#00b3f4'
            }
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,179,244,0.3)'
              },
              {
                offset: 1,
                color: 'rgba(0,179,244,0)'
              }
              ], false),
              shadowColor: 'rgba(0,179,244, 0.9)',
              shadowBlur: 20
            }
          },
          data: [60, 71, 56, 83, 79, 66, 69]
        },
        {
          name: '心率',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            normal: {
              color: '#00b3f4',
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5
            }
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#00b3f4'
            }
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,179,244,0.3)'
              },
              {
                offset: 1,
                color: 'rgba(0,179,244,0)'
              }
              ], false),
              shadowColor: 'rgba(0,179,244, 0.9)',
              shadowBlur: 20
            }
          },
          data: [50, 60, 45, 69, 65, 54, 60]
        }
        ]
      }
      this.chartInstance.setOption(initOption)
    },
    initChart2 () {
      var chartDom = document.getElementById('chart2')
      this.chartInstance = this.$echarts.init(chartDom)
      var colorList = ['#73DDFF', '#73ACFF', '#FDD56A', '#FDB36A', '#FD866A', '#9E87FF', '#58D5FF']
      var arr = [
        { value: 123, name: '高血压' },
        { value: 102, name: '正常范围' },
        { value: 122, name: '低血压' }
      ]
      const initOption = {
        color: colorList,
        title: {
        // text: 'PM2.5含量',
        // subtext:'50%',
          x: 'center',
          y: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 15
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [{
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['24%', '35%'],
          clockwise: true,
          avoidLabelOverlap: true,
          hoverOffset: 15,
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            show: true,
            position: 'outside',
            formatter: '{a|{b}：{d}%}\n{hr|}',
            rich: {
              hr: {
                backgroundColor: 't',
                borderRadius: 3,
                width: 3,
                height: 3,
                padding: [3, 3, 0, -12]
              },
              a: {
                padding: [-30, 15, -20, 15]
              }
            }
          },
          labelLine: {
            normal: {
              length: 20,
              length2: 30,
              lineStyle: {
                width: 1
              }
            }
          },
          data: arr
        }]
      }
      this.chartInstance.setOption(initOption)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #333;

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: calc(100% - 63px);
    margin-top: 10px;
    padding: 50px 65px;
    padding-right: 100px;
    background-image: url('../img/dialogContentBg.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 32px;
      color: #fff;
      font-size: 34px;
      cursor: pointer;
    }

    .head {
      width: 100%;
      height: 340px;
      border: 1px solid #03BCD7;
      border-radius: 6px;
      margin-bottom: 15px;

      .chart1 {
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 10px;
      }
    }

    .footer {
      width: 100%;
      height: calc(100% - 380px);
      display: flex;
      justify-content: space-between;

      .item {
        width: 48%;
        height: 100%;
        border: 1px solid #03BCD7;
        border-radius: 6px;

        .tr {
          display: flex;
          align-items: center;
          width: 98%;
          margin: 0 auto;
          border: 1px solid #fff;
          box-sizing: border-box;
          border-top: none;

          .td {
            flex: 1;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            font-size: 18px;
          }
        }

        .border {
          border-top: .0125rem solid #fff;
          margin-top: 20px;
        }

        .chart2 {
          width: 100%;
          height: calc(100% - 80px);
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
