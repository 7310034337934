<template>
  <div class="page">
    <div class="title">
      <span>心率概览</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="head">
        <div class="title">
          <span>心率趋势图</span>
        </div>
        <div class="chart1" id="chart1"></div>
      </div>
      <div class="footer">
        <div class="item">
          <div class="title">
            <span>心率统计</span>
          </div>
          <div class="chart2" id="chart2"></div>
        </div>
        <div class="item">
          <div class="title">
            <span>最低心率</span>
          </div>
          <div class="chart2" id="chart3"></div>
        </div>
        <div class="item">
          <div class="title">
            <span>平均心率</span>
          </div>
          <div class="chart2" id="chart4"></div>
        </div>
        <div class="item">
          <div class="title">
            <span>最高心率</span>
          </div>
          <div class="chart2" id="chart5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chart1: null,
      chart2: null,
      chart3: null,
      chart4: null,
      chart5: null
    }
  },
  mounted () {
    this.initChart1()
    this.initChart2()
    this.initChart3()
    this.initChart4()
    this.initChart5()
  },
  methods: {
    initChart1 () {
      var chartDom = document.getElementById('chart1')
      this.chart1 = this.$echarts.init(chartDom)
      const initOption = {
        title: {
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: 20
          },
          top: '5%',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(0, 255, 233,0)'
                }, {
                  offset: 0.5,
                  color: 'rgba(255, 255, 255,1)'
                }, {
                  offset: 1,
                  color: 'rgba(0, 255, 233,0)'
                }],
                global: false
              }
            }
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '15%'
        // containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            show: true
          },
          splitArea: {
            // show: true,
            color: '#f00',
            lineStyle: {
              color: '#f00'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          },
          boundaryGap: false,
          data: ['2022-12-14', '2022-12-14', '2022-12-14', '2022-12-14', '2022-12-14', '2022-12-14']

        }],

        yAxis: [{
          type: 'value',
          min: 0,
          // max: 140,
          splitNumber: 4,
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.1)'
            }
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            margin: 20,
            textStyle: {
              color: '#d1e6eb'

            }
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '心率趋势',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            normal: {
              color: '#00b3f4',
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5
            }
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#00b3f4'
            }
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          tooltip: {
            show: true
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,179,244,0.3)'
              },
              {
                offset: 1,
                color: 'rgba(0,179,244,0)'
              }
              ], false),
              shadowColor: 'rgba(0,179,244, 0.9)',
              shadowBlur: 20
            }
          },
          data: [5.8, 4.9, 6.7, 5.9, 6.1, 5.7, 6.2]
        }
        ]
      }
      this.chart1.setOption(initOption)
    },
    initChart2 () {
      var chartDom = document.getElementById('chart2')
      this.chart2 = this.$echarts.init(chartDom)
      const initOption = {
        title: {
          text: '54总检测次数',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#0580f2',
            fontSize: 14
          }
        },
        color: ['rgba(176, 212, 251, 0.8)'],
        // legend: {
        //   show: true,
        //   itemGap: 12,
        //   data: ['01', '02']
        // },

        series: [{
          name: 'Line 1',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            value: 50,
            name: '01',
            itemStyle: {
              normal: {
                color: { // 完成的圆环的颜色
                  colorStops: [{
                    offset: 0,
                    color: '#08A2C5' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#30F3AA' // 100% 处的颜色
                  }]
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            }
          }, {
            name: '02',
            value: 20
          }]
        }]
      }
      this.chart2.setOption(initOption)
    },
    initChart3 () {
      var chartDom = document.getElementById('chart3')
      this.chart3 = this.$echarts.init(chartDom)
      const initOption = {
        title: {
          text: '2.7次',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#0580f2',
            fontSize: 14
          }
        },
        color: ['rgba(176, 212, 251, 0.8)'],
        // legend: {
        //   show: true,
        //   itemGap: 12,
        //   data: ['01', '02']
        // },

        series: [{
          name: 'Line 1',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            value: 20,
            name: '01',
            itemStyle: {
              normal: {
                color: { // 完成的圆环的颜色
                  colorStops: [{
                    offset: 0,
                    color: '#1B7BF3' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#27A5FF' // 100% 处的颜色
                  }]
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            }
          }, {
            name: '02',
            value: 40
          }]
        }]
      }
      this.chart3.setOption(initOption)
    },
    initChart4 () {
      var chartDom = document.getElementById('chart4')
      this.chart4 = this.$echarts.init(chartDom)
      const initOption = {
        title: {
          text: '8.7次',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#0580f2',
            fontSize: 14
          }
        },
        color: ['rgba(176, 212, 251, 0.8)'],
        // legend: {
        //   show: true,
        //   itemGap: 12,
        //   data: ['01', '02']
        // },

        series: [{
          name: 'Line 1',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            value: 20,
            name: '01',
            itemStyle: {
              normal: {
                color: { // 完成的圆环的颜色
                  colorStops: [{
                    offset: 0,
                    color: '#FF6337' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#FFB123' // 100% 处的颜色
                  }]
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            }
          }, {
            name: '02',
            value: 70
          }]
        }]
      }
      this.chart4.setOption(initOption)
    },
    initChart5 () {
      var chartDom = document.getElementById('chart5')
      this.chart5 = this.$echarts.init(chartDom)
      const initOption = {
        title: {
          text: '18.8次',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#0580f2',
            fontSize: 14
          }
        },
        color: ['rgba(176, 212, 251, 0.8)'],
        // legend: {
        //   show: true,
        //   itemGap: 12,
        //   data: ['01', '02']
        // },

        series: [{
          name: 'Line 1',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            value: 20,
            name: '01',
            itemStyle: {
              normal: {
                color: { // 完成的圆环的颜色
                  colorStops: [{
                    offset: 0,
                    color: '#6E4CF6' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#BB8EF5' // 100% 处的颜色
                  }]
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            }
          }, {
            name: '02',
            value: 60
          }]
        }]
      }
      this.chart5.setOption(initOption)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #333;

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: calc(100% - 63px);
    margin-top: 10px;
    padding: 50px 65px;
    padding-right: 100px;
    background-image: url('../img/dialogContentBg.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 32px;
      color: #fff;
      font-size: 34px;
      cursor: pointer;
    }

    .head {
      width: 100%;
      height: 340px;
      border: 1px solid #03BCD7;
      border-radius: 6px;
      margin-bottom: 15px;

      .chart1 {
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 10px;
      }
    }

    .footer {
      width: 100%;
      height: calc(100% - 380px);
      display: flex;
      justify-content: space-between;

      .item {
        width: 24%;
        height: 100%;
        border: 1px solid #03BCD7;
        border-radius: 6px;

        .tr {
          display: flex;
          align-items: center;
          width: 98%;
          margin: 0 auto;
          border: 1px solid #fff;
          box-sizing: border-box;
          border-top: none;

          .td {
            flex: 1;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            font-size: 18px;
          }
        }

        .border {
          border-top: 1px solid #fff;
          margin-top: 20px;
        }

        .chart2 {
          width: 100%;
          height: calc(100% - 80px);
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
